import type { ClientCredentials, UserDetails } from '../types';
import type { Instant } from '@js-joda/core';
import type { AppAction } from 'src/store/actions';

export interface TokenState {
    token: string;
    expires?: Instant;
}

export interface AuthState {
    /** null = none; undefined = uninitialized */
    refreshToken?: TokenState | null | undefined;
    /** null = none; undefined = uninitialized */
    accessToken?: TokenState | null | undefined;
    /** null = none; undefined = uninitialized */
    masqAccessToken?: TokenState | null | undefined;
    /** null = none; undefined = uninitialized */
    idToken?: TokenState | null | undefined;

    clientCredentials?: ClientCredentials;

    masqUser?: UserDetails;
    user?: UserDetails;
    error?: string;
}

export const initialState: AuthState = {};

export const auth = (state = initialState, action: AppAction): AuthState => {
    switch (action.type) {
        case 'client-credentials::set': {
            return { ...state, clientCredentials: action.clientCredentials };
        }
        case 'auth::error::clear':
            const { error: _error, ...rest } = state;
            return rest;
        case 'auth::error::set':
            return { ...state, error: action.error };
        case 'access-token::clear':
            return { ...state, accessToken: null };
        case 'access-token::set':
            return {
                ...state,
                accessToken: action.token,
            };
        case 'id-token::set':
            return {
                ...state,
                idToken: action.token,
            };
        case 'refresh-token::clear':
            return { ...state, refreshToken: null };
        case 'refresh-token::set':
            return { ...state, refreshToken: action.token };
        case 'user-details::set':
            return { ...state, user: action.userDetails };
        case 'masq::clear':
            return { ...state, masqAccessToken: null, masqUser: undefined };
        case 'masq::access-token::set':
            return { ...state, masqAccessToken: action.token };
        case 'masq::user-details::set':
            return { ...state, masqUser: action.userDetails };

        default:
            return state;
    }
};
