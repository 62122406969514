import type { TokenState } from '../reducers/auth';
import type { ClientCredentials, UserDetails } from '../types';

export type SetClientCredentials = {
    clientCredentials?: ClientCredentials;
    type: 'client-credentials::set';
};

export const setClientCredentials = (clientCredentials?: ClientCredentials): SetClientCredentials => ({
    type: 'client-credentials::set',
    clientCredentials,
});

export type SetTokenAction = {
    type: 'refresh-token::set' | 'access-token::set' | 'masq::access-token::set' | 'id-token::set';
    token: TokenState | null;
    isInitial: boolean;
};

export const setAccessToken = (token: TokenState | null, isInitial = false): SetTokenAction => ({
    type: 'access-token::set',
    isInitial,
    token,
});
export const setIdToken = (token: TokenState | null): SetTokenAction => ({
    type: 'id-token::set',
    isInitial: false,
    token,
});
export const setRefreshToken = (token: TokenState | null, isInitial = false): SetTokenAction => ({
    type: 'refresh-token::set',
    isInitial,
    token,
});
export const setMasqToken = (token: TokenState | null, isInitial = false): SetTokenAction => ({
    type: 'masq::access-token::set',
    isInitial,
    token,
});

export type ClearTokenAction = {
    type: 'refresh-token::clear' | 'access-token::clear' | 'masq::clear';
    reason?: string;
};
export const clearAccessToken = (reason?: string): ClearTokenAction => ({
    type: 'access-token::clear',
    reason,
});
export const clearRefreshToken = (reason?: string): ClearTokenAction => ({
    type: 'refresh-token::clear',
    reason,
});
export const clearMasq = (reason?: string): ClearTokenAction => ({
    type: 'masq::clear',
    reason,
});

export type SetUserDetailsAction = { type: 'masq::user-details::set' | 'user-details::set'; userDetails?: UserDetails };
export const setUserDetails = (userDetails?: UserDetails): SetUserDetailsAction => ({
    type: 'user-details::set',
    userDetails,
});
export const setMasqUserDetails = (userDetails?: UserDetails): SetUserDetailsAction => ({
    type: 'masq::user-details::set',
    userDetails,
});

export const setAuthError = (error: string) => ({ type: 'auth::error::set' as const, error });
export type SetAuthErrorAction = ReturnType<typeof setAuthError>;

export const clearAuthError = () => ({ type: 'auth::error::clear' as const });
export type ClearAuthErrorAction = ReturnType<typeof clearAuthError>;

export type AuthAction =
    | SetClientCredentials
    | ClearAuthErrorAction
    | ClearTokenAction
    | SetAuthErrorAction
    | SetTokenAction
    | SetUserDetailsAction;
